import { MarkdownToJSX } from 'markdown-to-jsx';

import { StyledMarkdown } from './Markdown.styled';

interface MarkdownProps {
  center?: boolean;
  children?: string;
  className?: string;
  options?: MarkdownToJSX.Options;
}

export const Markdown: React.FC<MarkdownProps> = ({
  center,
  children,
  className,
  options,
}) => {
  return (
    <StyledMarkdown
      $center={center}
      options={{ forceBlock: true, ...options }}
      className={className}
    >
      {children ?? ''}
    </StyledMarkdown>
  );
};
